<template>
  <div class="app-container">
    <div class="filter-container">
      <el-select class="filter-item" v-model="listQuery.status" placeholder="状态" style="width: 160px" clearable>
        <el-option label="审核中" :value="0"></el-option>
        <el-option label="已完成" :value="1"></el-option>
        <el-option label="已驳回" :value="2"></el-option>
      </el-select>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
    </div>
    <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column align="center" label="ID" width="60">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="申请人" min-width="110" align="center">
        <template slot-scope="scope">
          <div style="display: flex;flex-direction:column;align-items:center;text-align:center;">
            <img :src="scope.row.user.avatar" style="width: 26px;height:26px;border-radius:4px;margin-right:4px;">
            <span>{{ scope.row.user.name }}</span>
            <span>{{ scope.row.user.phone }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="提现金额" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.amount }}
        </template>
      </el-table-column>
      <el-table-column label="收款方式" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.payment_method == '1'" type="primary">零钱</el-tag>
          <el-tag v-else-if="scope.row.payment_method == '2'" type="info">银行卡</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="开户行" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.bank_name }}
        </template>
      </el-table-column>
      <el-table-column label="银行卡号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.bank_no }}
        </template>
      </el-table-column>
      <el-table-column label="姓名" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="created_at" label="创建时间" min-width="200">
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ scope.row.created_at }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status == 0" type="primary">审核中</el-tag>
          <el-tag v-else-if="scope.row.status == 1" type="success">已完成</el-tag>
          <template v-else-if="scope.row.status == 2">
            <el-tag type="warning">已驳回</el-tag>
            <div>原因:{{scope.row.refuse_reason}}</div>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" align="center" width="200" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button v-if="scope.row.status == 0" type="primary" size="mini" @click="handleWithdraw(scope.row)">处理</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>

    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="50%"
      :close-on-click-modal="false">
      <el-form label-position="left" label-width="90px">
        <el-form-item label="处理结果" prop="name">
          <el-radio v-model="form.status" :label="1" border>完成</el-radio>
          <el-radio v-model="form.status" :label="2" border>驳回</el-radio>
        </el-form-item>
        <el-form-item v-if="form.status == 1" label="打款" prop="name">
          <el-radio v-model="form.pay_type" :label="1" border>立即使用商户平台打款</el-radio>
          <el-radio v-model="form.pay_type" :label="2" border>已完成线下打款</el-radio>
        </el-form-item>
        <el-form-item v-if="form.status == 2" label="驳回原因" prop="phone">
          <el-input v-model="form.refuse_reason" placeholder="请输入驳回原因" clearable/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      memberLevels: [],
      memberPurchaseLevels: [],
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        school_id: "",
        status: "",
      },
      dialogVisible: false,
      btnLoading: false,
      form: {
        status: 1,
        pay_type: 1,
        refuse_reason: '',
      }
    };
  },
  created() {
    this.getList();
  },
  computed: {
    ...mapGetters(["schools", "school_id"])
  },
  watch: {
    school_id(newVal, oldVal) {
      this.listQuery.school_id = newVal;
      this.getList();
    },
  },
  methods: {
    getList() {
      this.listQuery.school_id = this.school_id;
      this.listLoading = true;
      request({
        url: "/api/backend/finance/withdrawList",
        method: "get",
        params: this.listQuery
      }).then(response => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleWithdraw(row) {
      this.form.id = row.id;
      this.dialogVisible = true;
    },
    saveData() {
      this.btnLoading = true;
      request({
        url: "/api/backend/finance/withdrawHandle",
        method: "post",
        data: this.form
      }).then(() => {
        this.dialogVisible = false;
        this.getList();
        this.$message({
          type: "success",
          message: "操作成功!"
        });
      }).finally(()=>{
        this.btnLoading = false;
      })
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
</style>
